import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import TravellersRoomInput from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomInput";
import "./TravellersRoomFieldset.scss";
import { FormattedMessage } from "react-intl";
import IconMinusRounded from "app/pages/.shared/static/icons/IconMinusRounded";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";

const TravellersRoomFieldset = ({
	index,
	showRemoveButton,
	onRemoveClick,
	warningMessage = false,
}) => {
	const handleRemoveClick = useCallback(() => {
		onRemoveClick(index);
	}, [index]);

	return (
		<div className="travellers-room-fieldset">
			<div className="travellers-room-fieldset__header">
				<div className="travellers-room-fieldset__title">
					<FormattedMessage
						id="sdp.search.occupancies.room.label"
						values={{ number: index + 1 }}
					/>
				</div>

				{showRemoveButton && (
					<div
						onClick={handleRemoveClick}
						data-testid={`remove-room-button-${index + 1}`}
						className="travellers-room-fieldset__remove-button"
					>
						<IconMinusRounded width={15} height={15} />
						<FormattedMessage id="sdp.search.occupancies.room.remove.label" />
					</div>
				)}
			</div>
			<div className="travellers-room-fieldset__content">
				{warningMessage && (
					<AlertMessage
						alertType={ALERT_TYPE.INFO}
						message={<FormattedMessage id="general.warning.adult.destination.usa" />}
						className="travellers-room-fieldset__warning"
					/>
				)}
				<TravellersRoomInput name={`occupancies.${index}`} index={index} />
			</div>
		</div>
	);
};

TravellersRoomFieldset.propTypes = {
	index: PropTypes.number,
	showRemoveButton: PropTypes.bool,
	onRemoveClick: PropTypes.func,
	warningMessage: PropTypes.bool,
};

export default memo(TravellersRoomFieldset);
