import { components } from "react-select";
import { memo } from "react";
import IconCloseFull from "app/pages/.shared/IconCloseFull";

const ClearIndicatorSdpSearchInput = ({ ...props }) => {
	return (
		<components.ClearIndicator {...props}>
			<IconCloseFull width={24} height={24} />
		</components.ClearIndicator>
	);
};

export default memo(ClearIndicatorSdpSearchInput);
