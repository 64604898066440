import { memo, useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { messagePropType } from "app/utils/propTypes";
import "./TravellersRoomButton.scss";
import classNames from "classnames";
import { scrollToElement } from "app/utils/scroller";
import TravellersRoomForm from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomForm";
import { Drawer } from "app/pages/.shared/Drawer";
import { FormattedMessage } from "react-intl";
import IconClose from "app/pages/.shared/IconClose";
import { useFormikContext } from "formik";
import useHidePartnerCustomHeader from "app/utils/hooks/useHidePartnerCustomHeader";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const TravellersRoomDrawerButton = props => {
	const [open, setOpen] = useState(false);

	const { errors, validateForm, values: formValues } = useFormikContext();

	const {
		label,
		className,
		initialValues = [],
		valueToDisplay,
		onConfirmation = () => {},
		id,
		validateOnMount,
	} = props;
	const ref = useRef();
	useHidePartnerCustomHeader({ hide: open });

	const handleValidation = useCallback(
		values => {
			setOpen(false);
			if (ref && ref.current) {
				scrollToElement({ element: ref.current, offset: -300, disableSmoothScroll: false });
			}

			// relaunch validation to remove error color if there is no errors
			validateForm({
				...formValues,
				...values,
			});

			onConfirmation(values.occupancies);
		},
		[ref.current, formValues]
	);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const inputClassName = classNames(
		"travellers-room-button",
		"travellers-room-button--touched",
		className,
		{
			"travellers-room-button--error": errors.occupancies,
			"travellers-room-button--opened": open,
		}
	);

	const Form = (
		<TravellersRoomForm
			initialValues={initialValues}
			handleValidation={handleValidation}
			validateOnMount={validateOnMount}
		/>
	);

	return (
		<div className={inputClassName} ref={ref} id={id}>
			<label className="travellers-room-button__label">
				<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
					{label}
				</Typography>
			</label>
			<div
				onClick={handleOpen}
				className="travellers-room-button__input"
				data-testid="travellers-room-button"
			>
				{valueToDisplay}
			</div>
			<Drawer
				drawerClassName="travellers-room-button__drawer"
				isOpen={open}
				onClose={handleClose}
				direction={"btt"}
			>
				<div className="travellers-room-button__header">
					<Typography variant={TYPOGRAPHY_VARIANTS.XL} isBold>
						<FormattedMessage id="sdp.search.occupancies.title" />
					</Typography>
					<div onClick={handleClose} className="travellers-room-button__header-icon">
						<IconClose width={24} height={24} />
					</div>
				</div>
				{Form}
			</Drawer>
		</div>
	);
};

TravellersRoomDrawerButton.propTypes = {
	label: messagePropType,
	className: PropTypes.string,
	valueToDisplay: PropTypes.element,
	onConfirmation: PropTypes.func,
	initialValues: PropTypes.array,
	validateOnMount: PropTypes.bool,
	id: PropTypes.string,
};

export default memo(TravellersRoomDrawerButton);
