import { useEffect, useState } from "react";
import { isServerSide } from "app/utils/utils";

const getViewports = () =>
	!isServerSide
		? {
				viewport: {
					width: window?.innerWidth || 0,
					height: window?.innerHeight || 0,
				},
				visualViewport: {
					width: window?.visualViewport?.width,
					height: window?.visualViewport?.height,
				},
		  }
		: {};

const hasVirtualKeyboard = () => {
	if (!isServerSide) {
		return window?.navigator?.maxTouchPoints > 1 && window?.innerWidth <= 768
			? window?.visualViewport?.height < window?.innerHeight
			: false;
	}
	return false;
};

/*
 * Hook for detection screen size and virutal keyboard
 * Values return:
 * 	viewport: object { viewport and visualViewport } - https://stackoverflow.com/a/7371009
 * 	hasKeyboard: boolean - detect appearance of virtual keyboard
 */
const useVisualViewport = () => {
	const [viewports, setViewports] = useState(getViewports());
	const [hasKeyboard, setHasKeyboard] = useState(hasVirtualKeyboard());

	useEffect(() => {
		const handleResize = () => {
			setViewports(getViewports());
			setHasKeyboard(hasVirtualKeyboard());
		};
		if (window) {
			window.visualViewport.addEventListener("resize", handleResize);
		}
		return () => {
			if (window) {
				window.visualViewport.removeEventListener("resize", handleResize);
			}
		};
	}, []);

	return [viewports, hasKeyboard];
};

export default useVisualViewport;
