import { memo, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import SidePanel from "app/pages/.shared/SidePanel/SidePanel";
import { useField } from "formik";
import DepartureDateSidePanelContent from "app/pages/SmartDP/Search/DepartureDateSidePanelContent/DepartureDateSidePanelContent";
import { FormattedDate, FormattedMessage } from "react-intl";
import "./DateCalendarInput.scss";
import classNames from "classnames";
import useHidePartnerCustomHeader from "app/utils/hooks/useHidePartnerCustomHeader";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const DateCalendarDrawerInput = ({
	id,
	className,
	departureDateMin,
	departureDateMax,
	checkDateAvailability = () => {},
	isCalendarDisabled,
	calendarDisabledView,
	name,
	"data-testid": dataTestId,
	displayCalendarIcon = true,
	onClose = () => {},
	departureLabel,
	returnLabel,
}) => {
	const [field, meta, helpers] = useField({
		name,
	});

	const [open, setOpen] = useState(false);
	useHidePartnerCustomHeader({ hide: open });

	const { setError } = helpers;

	const handleClose = useCallback(() => {
		if (!field?.value?.endDate) {
			helpers.setValue({});
		}
		setOpen(false);
		onClose();
	}, [field?.value?.endDate]);

	const handleOpen = useCallback(() => {
		setOpen(true);
		setError();
	}, []);

	const handleDateSelect = useCallback(date => {
		helpers.setValue({
			departureDate: date[0],
			endDate: date[1],
		});
	}, []);

	const handleDateConfirmation = date => {
		if (date.length !== 2) {
			helpers.setValue({});
		}
		helpers.setTouched(true);
		setOpen(false);
	};

	const isTouched = (meta.touched && !meta.error) || open || field.value?.departureDate;

	const getInputClassName = type =>
		classNames(`date-calendar-input date-calendar-input__${type}`, className, {
			[`date-calendar-input__${type}--opened`]: open,
			[`date-calendar-input__${type}--touched`]: isTouched,
			[`date-calendar-input__${type}--error`]: meta.touched && meta.error,
		});
	const inputClassNameDeparture = useMemo(
		() => getInputClassName("departure", className, open, isTouched, meta),
		[className, open, isTouched, meta]
	);

	const inputClassNameReturn = useMemo(
		() => getInputClassName("return", className, open, isTouched, meta),
		[className, open, isTouched, meta]
	);
	const departureDateLabel = departureLabel ? (
		departureLabel
	) : (
		<FormattedMessage id="sdp.search.departure.date.side.panel.title" />
	);
	const returnDateLabel = returnLabel ? (
		returnLabel
	) : (
		<FormattedMessage id="sdp.search.destination.date.side.panel.title" />
	);

	return (
		<>
			<div
				className="date-calendar-input__container"
				onClick={handleOpen}
				data-testid={dataTestId}
				tabIndex="0"
			>
				<div
					name="departureDate"
					className={`${inputClassNameDeparture} sdp-search-form__field-dates-departure`}
					data-testid={`${dataTestId}-departure`}
					onFocus={handleOpen}
				>
					<label htmlFor={id} className="date-calendar-input__label">
						{isTouched ? (
							<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
								{departureDateLabel}
							</Typography>
						) : (
							<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>
								{departureDateLabel}
							</Typography>
						)}
					</label>
					<div className="date-calendar-input__input">
						{field.value?.departureDate && (
							<FormattedDate
								value={field.value?.departureDate}
								day="2-digit"
								month="2-digit"
								year={"numeric"}
							/>
						)}
					</div>
				</div>

				<div
					name="endDate"
					className={`${inputClassNameReturn} sdp-search-form__field-dates-return`}
					data-testid={`${dataTestId}-return`}
					onFocus={handleOpen}
				>
					<label htmlFor={id} className="date-calendar-input__label">
						{isTouched ? (
							<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
								{returnDateLabel}
							</Typography>
						) : (
							<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>
								{returnDateLabel}
							</Typography>
						)}
					</label>
					<div className="date-calendar-input__input">
						{field.value?.endDate && (
							<FormattedDate
								value={field.value?.endDate}
								day="2-digit"
								month="2-digit"
								year={"numeric"}
							/>
						)}
					</div>
				</div>
			</div>
			<SidePanel isOpen={open} onClose={handleClose} direction={"btt"}>
				<DepartureDateSidePanelContent
					onClose={handleClose}
					initialDate={[field.value?.departureDate, field.value?.endDate]}
					onDateSelect={handleDateSelect}
					onDateConfirm={handleDateConfirmation}
					departureDateMin={departureDateMin}
					departureDateMax={departureDateMax}
					checkDateAvailability={checkDateAvailability}
					isCalendarDisabled={isCalendarDisabled}
					calendarDisabledView={calendarDisabledView}
					displayCalendarIcon={displayCalendarIcon}
					departureDateLabel={departureDateLabel}
					returnDateLabel={returnDateLabel}
				/>
			</SidePanel>
		</>
	);
};

DateCalendarDrawerInput.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
	checkDateAvailability: PropTypes.func,
	isCalendarDisabled: PropTypes.bool,
	name: PropTypes.string,
	"data-testid": PropTypes.string,
	calendarDisabledView: PropTypes.element,
	displayCalendarIcon: PropTypes.bool,
	departureLabel: PropTypes.string,
	returnLabel: PropTypes.string,
	onClose: PropTypes.func,
};

export default memo(DateCalendarDrawerInput);
