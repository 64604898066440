import { useEffect } from "react";

const useHidePartnerCustomHeader = ({ hide }) => {
	useEffect(
		// eslint-disable-next-line consistent-return
		() => {
			const partnerDynamicHeader = window.document.querySelector(".partner-dynamic-header");

			if (hide) {
				if (partnerDynamicHeader) {
					partnerDynamicHeader.style.display = "none";
				}

				return () => {
					if (partnerDynamicHeader) {
						partnerDynamicHeader.style.display = "block";
					}
				};
			}
		},
		[hide]
	);
};

export default useHidePartnerCustomHeader;
