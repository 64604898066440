import { useState, useEffect } from "react";

export const DIRECTION = {
	RTL: "rtl",
	LTR: "ltr",
};

export default () => {
	const [direction, setDirection] = useState(DIRECTION.LTR);

	useEffect(() => {
		let observer;
		if (document) {
			const htmlElement = document?.documentElement;

			const updateDirection = () => {
				const newDir = htmlElement.getAttribute("dir");
				setDirection(newDir || DIRECTION.LTR);
			};

			const observer = new MutationObserver(updateDirection);
			observer.observe(htmlElement, { attributes: true, attributeFilter: ["dir"] });

			updateDirection();
		}

		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, []);

	return direction;
};
