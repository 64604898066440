import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import AdvancedSelectFormik from "app/pages/.shared/form/AdvancedSelectFormik/AdvancedSelectFormik";
import { memo, useContext } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import SmartDPInputSidePanelContent from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPInputSidePanelContent";

const SmartDPSearchInput = props => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	return isMobile ? (
		<SmartDPInputSidePanelContent {...props} />
	) : (
		<AdvancedSelectFormik {...props} menuPortalMargin={-8} />
	);
};
export default memo(SmartDPSearchInput);
