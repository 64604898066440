import { memo } from "react";
import { FormattedMessage } from "react-intl";
import "./CalendarDisabledView.scss";
import Button from "app/pages/.shared/form/Button";
import PropTypes from "prop-types";

const CalendarDisabledView = ({ mainMessage, secondaryMessage, onDismiss }) => {
	return (
		<div className="calendar-disabled-view" data-testid="calendar-disabled-view">
			<div className="calendar-disabled-view__content">
				{mainMessage && <div className="calendar-disabled-view__title">{mainMessage}</div>}
				{secondaryMessage && (
					<div className="calendar-disabled-view__description">{secondaryMessage}</div>
				)}

				{onDismiss && (
					<div className="calendar-disabled-view__cta">
						<Button variant="primary" onClick={onDismiss}>
							<FormattedMessage id="sdp.calendar.unavailable.date.change.cta.label" />
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

CalendarDisabledView.propTypes = {
	onDismiss: PropTypes.func,
	mainMessage: PropTypes.string,
	secondaryMessage: PropTypes.string,
};

export default memo(CalendarDisabledView);
