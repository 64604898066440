import { memo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import NumberInputFormik from "app/pages/.shared/NumberInputFormik";
import "./TravellersRoomInput.scss";
import { FieldArray, useFormikContext } from "formik";
import get from "lodash/get";
import usePrevious from "app/utils/hooks/usePrevious";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE, CHILDREN_AGE_LIMIT, MAX_PARTICIPANTS, PASSENGER_TYPE } from "app/constants";
import classNames from "classnames";
import DateSelectorInputFormik from "app/pages/.shared/DateSelector/DateSelectorInputFormik";
import { isEmpty } from "lodash";

const TravellersRoomInput = ({ name, index }) => {
	const { getFieldProps, setFieldValue, getFieldMeta, errors, isSubmitting } = useFormikContext();

	// there must be always at least 1 adult per room
	const canDecrement = useCallback(value => value > 1, []);

	const occupancy = getFieldProps(name).value;
	const meta = getFieldMeta(name);

	const previousChildren = usePrevious(occupancy.children);

	// Calculating the total number of participants
	const totalParticipants = occupancy.adults + occupancy.children;

	const canIncrementAdults = totalParticipants < MAX_PARTICIPANTS;
	const canIncrementChildren = totalParticipants < MAX_PARTICIPANTS;

	useEffect(() => {
		const actualChildrenCount = occupancy.children;
		let childrenBirthdates = occupancy.childrenBirthdates || [];

		if (
			actualChildrenCount > previousChildren &&
			childrenBirthdates.length !== actualChildrenCount
		) {
			// attention à ne pas muter childrenBirthdates
			childrenBirthdates = childrenBirthdates.concat([
				{
					day: undefined,
					month: undefined,
					year: undefined,
				},
			]);
		} else if (actualChildrenCount < previousChildren) {
			// attention à ne pas muter childrenBirthdates
			childrenBirthdates = childrenBirthdates.slice(0, childrenBirthdates.length - 1);
		}

		setFieldValue(`${name}.childrenBirthdates`, childrenBirthdates);
	}, [occupancy.children]);

	const childrenBirthdates = occupancy.childrenBirthdates || [];

	// Filtrer les valeurs undefined pour éviter un tableau avec des [empty].
	const birthDateErrors = get(errors, `occupancies[${index}].childrenBirthdates`, []).filter(
		value => value !== undefined
	);

	const firstError =
		!isEmpty(birthDateErrors) && birthDateErrors.find(birthDateError => birthDateError);

	return (
		<div className="travellers-room-input">
			<div className="travellers-room-input__item">
				<div className="travellers-room-input__label">
					<FormattedMessage id="search.engine.adults.label" />
					<FormattedMessage id="search.engine.adults.age.range.label">
						{msg => <div className="travellers-room-input__label--note">{msg}</div>}
					</FormattedMessage>
				</div>
				<div className="travellers-room-input__input">
					<NumberInputFormik
						id={`${name}.adults`}
						name={`${name}.adults`}
						canDecrement={canDecrement}
						canIncrement={() => canIncrementAdults}
					/>
				</div>
			</div>
			<div className="travellers-room-input__item">
				<div className="travellers-room-input__label">
					<FormattedMessage id="search.engine.children.label" />
					<FormattedMessage
						id="search.engine.children.age.range.label"
						values={CHILDREN_AGE_LIMIT}
					>
						{msg => <div className="travellers-room-input__label--note">{msg}</div>}
					</FormattedMessage>
				</div>
				<div className="travellers-room-input__input">
					<NumberInputFormik
						id={`${name}.children`}
						name={`${name}.children`}
						canIncrement={() => canIncrementChildren}
					/>
				</div>
			</div>

			{birthDateErrors?.length > 0 && !meta.isValid && !meta.isSubmitting && (
				<AlertMessage
					message={<FormattedMessage id={firstError.id} />}
					alertType={ALERT_TYPE.ERROR}
				/>
			)}

			{childrenBirthdates.length > 0 && (
				<div className="travellers-room-input__birdthdates">
					<FieldArray
						name="occupancies.childrenBirthdates"
						render={() => {
							return childrenBirthdates.map((age, index) => {
								return (
									<div
										key={index}
										className={classNames({
											"travellers-room-input__birdthdate": true,
											"travellers-room-input__birdthdate--has-value":
												age.day && age.month && age.year,
										})}
									>
										<div className="travellers-room-input__child-index">
											<FormattedMessage
												id="payment.passengers.child"
												values={{ index: index + 1 }}
											/>
										</div>
										<DateSelectorInputFormik
											isChildrenAgeRequired={true}
											id={`${name}.childrenBirthdates.${index}`}
											name={`${name}.childrenBirthdates.${index}`}
											className="travellers-room-input__children-age"
											data-testid={`${name}-childrenBirthdates-${index}-input`}
											label={
												<FormattedMessage id="search.engine.birthdate.label" />
											}
											isSubmitting={isSubmitting}
											type={PASSENGER_TYPE.CHILD}
										/>
									</div>
								);
							});
						}}
					/>
				</div>
			)}
		</div>
	);
};

TravellersRoomInput.propTypes = {
	name: PropTypes.string,
	index: PropTypes.number,
};

export default memo(TravellersRoomInput);
